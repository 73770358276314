<template>
  <div>
    <div class="px-2 mt-1">
      <header-slot />
      <b-nav card-header pills class="m-0">
        <b-nav-item
          exact
          exact-active-class="active"
          :active="isActiveClaims"
          :link-classes="['px-3', bgTabsNavs]"
          @click="() => $router.push({ name: 'source-claims' })"
        >
          SETTING CLAIMS
        </b-nav-item>

        <!-- <b-nav-item
          :to="{ name: 'hours-claims' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          OFFICE HOURS
        </b-nav-item> -->
        <b-nav-item
          :to="{ name: 'follow-up' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          FOLLOW-UP DAYS
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'surveys' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          SURVEYS
        </b-nav-item>
      </b-nav>

      <b-card
        no-body
        
        :class=" 'border-top-primary border-3 border-table-radius p-2' "
      >
        <template v-if="isActiveClaims">
          <b-nav card-header pills class="m-0">
            <b-nav-item
              :to="{ name: 'source-claims' }"
              exact
              active-class="bg-info box-shadow-info border-info info"
              exact-active-class="active"
              :link-classes="['sub-tab px-3', bgTabsNavs]"
            >
              SOURCE CLAIMS
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'solution-claims' }"
              exact
              active-class="bg-info box-shadow-info border-info info"
              exact-active-class="active"
              :link-classes="['sub-tab px-3', bgTabsNavs]"
            >
              SOLUTION CLAIMS
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'reason-claims' }"
              exact
              active-class="bg-info box-shadow-info border-info info"
              exact-active-class="active"
              :link-classes="['sub-tab px-3', bgTabsNavs]"
            >
              REASON CLAIMS
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'sanction-claims' }"
              exact
              active-class="bg-info box-shadow-info border-info info"
              exact-active-class="active"
              :link-classes="['sub-tab px-3', bgTabsNavs]"
            >
              SANCTION CLAIMS
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'type-claims' }"
              exact
              active-class="bg-info box-shadow-info border-info info"
              exact-active-class="active"
              :link-classes="[bgTabsNavs, 'sub-tab px-3']"
            >
              TYPE CLAIMS
            </b-nav-item>
          </b-nav>
          <b-card
            no-body
            class="border-top-info border-3 border-table-radius px-0"
          >
            <router-view />
          </b-card>
        </template>
        <router-view v-else />
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Settings",
  computed: {
    isActiveClaims() {
      const routes = [
        "source-claims",
        "solution-claims",
        "reason-claims",
        "sanction-claims",
        "type-claims",
      ];
      return routes.includes(this.$route.name);
    },
  },
  created() {

  }
};
</script>